<template>
  <div>
    <LoadingOverlay v-if="isFetching" />
    <div v-else>
      <h4
        v-if="
          !isFetching &&
          (!getCampaignById.campaign_id || !getCampaignById.is_active)
        "
        class="mt-4 text-center"
      >
        No Campaign Found!
        <CButton
          color="link"
          style="font-weight: 700; font-size: large"
          @click="redirectToLogin"
        >
          Sign In</CButton
        >
      </h4>
      <CampaignLogin
        v-else-if="!candidate.is_pre_register"
        @candidateData="isPreRegister"
      />
      <PreRegisterCampaign
        v-else-if="candidate.is_pre_register"
        :candidatedata="candidate"
        @cancel="handleCancel()"
      />
    </div>
  </div>
</template>

<script>
import CampaignLogin from "@/pages/Campaign/CampaignLogin.vue";
import PreRegisterCampaign from "./PreRegisterCampaign.vue";

import { mapGetters, mapActions, mapState } from "vuex";
import { LOGIN_URL } from "@/helpers/helper";
export default {
  components: {
    CampaignLogin,
    PreRegisterCampaign,
  },
  data() {
    return {
      isFetching: false,
      candidate: {
        is_pre_register: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getCampaignById"]),
    ...mapState(["regCompletedCandidate"]),
  },
  methods: {
    ...mapActions(["fetchCampaignById", "fetchOrgDetailsByAcronym"]),
    redirectToLogin() {
      this.$router.push({ path: LOGIN_URL() });
    },
    isPreRegister(data) {
      const {
        is_pre_register,
        email,
        first_name,
        sur_name,
        phone,
        candidate_uid,
        is_campaign,
        campaign_pre_reg_candidate_id,
        candidate_type,
        candidate_type_id,
        org_name,
        register_now,
        completed,
        step,
      } = data;
      this.candidate = {
        is_pre_register,
        email,
        first_name,
        sur_name,
        phone,
        candidate_uid,
        is_campaign,
        campaign_pre_reg_candidate_id,
        candidate_type_id,
        candidate_type,
        org_name,
        register_now,
        completed,
        step,
      };
    },
    handleCancel() {
      this.candidate = {
        is_pre_register: false,
      };
    },
  },
  mounted() {
    const {
      params: { campaign_id: cid, org_name },
    } = this.$router.currentRoute;
    this.campaign_id = cid || "";
    this.isFetching = true;
    let appendAction = [];
    appendAction = [...appendAction, this.fetchOrgDetailsByAcronym(org_name)];
    appendAction = [...appendAction, this.fetchCampaignById(this.campaign_id)];
    Promise.all(appendAction).then(res=>{
      this.isFetching = false;
    })
    if(this.regCompletedCandidate) {
      this.candidate.is_pre_register = true;
      this.candidate.step = this.regCompletedCandidate.step;
    }
  },
};
</script>

<style lang="scss" scoped></style>
