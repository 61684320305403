<template>
  <div class="preview">
    <ValidationObserver ref="dynamicForm" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)" name="dynamicForm">
        <CRow v-for="(field, index) in fields" :key="index" class="mb-2">
          <CCol md="12" v-if="field.field_name">
            <CRow>
              <label
                class="col-lg-12 col-md-12"
                :class="{ required: field.is_mandatory }"
              >
                <span class="font-weight-bold">{{
                  field.field_name
                }}</span></label
              >
              <CCol md="12" v-if="field.field_type_id">
                <ValidationProvider
                  :rules="{
                    required: field.is_mandatory,
                    email: field.field_type_id.code === 8,
                    phone_rule:
                      field.field_type_id.code === 9 && /^\+\d{1,3}\^\d{1,15}$/,
                  }"
                  v-slot="{ errors }"
                >
                  <Select
                    v-if="[6, 7].includes(field.field_type_id.code)"
                    :name="`options_response_${index}`"
                    :value="field.answer.options_response"
                    :options="field.options || []"
                    @change="handleChangeSelect"
                    :multiple="field.field_type_id.code === 7"
                    :error="errors[0]"
                  />
                  <RadioButton
                    v-if="
                      field.field_type_id.code === 1 && field.field_id === 5
                    "
                    :name="`yes_no_response_${index}`"
                    :value="field.answer.yes_no_response"
                    :options="(options && options['registerRecruitment']) || []"
                    @change="handleRadio"
                    :error="errors[0]"
                  />
                  <RadioButton
                    v-else-if="field.field_type_id.code === 1"
                    :name="`yes_no_response_${index}`"
                    :value="field.answer.yes_no_response"
                    :options="(options && options['boolean']) || []"
                    @change="handleRadio"
                    :error="errors[0]"
                  />
                  <TextareaInput
                    v-if="[10].includes(field.field_type_id.code)"
                    :name="`text_area_response_${index}`"
                    :value="field.answer.text_area_response"
                    @input="handleInput"
                    :error="errors[0]"
                  />
                  <TextInput
                    v-if="
                      [2, 8].includes(field.field_type_id.code) &&
                      field.field_id !== 5
                    "
                    :name="`text_area_response_${index}`"
                    :value="field.answer.text_area_response"
                    @input="handleInput"
                    :error="errors[0]"
                  />
                  <TextInput
                    v-if="field.field_type_id.code === 3"
                    :name="`numeric_response_${index}`"
                    type="number"
                    :value="field.answer.numeric_response"
                    @input="handleInput"
                    :error="errors[0]"
                  />
                  <Rating
                    v-if="field.field_type_id.code === 5"
                    :name="`rating_count_${index}`"
                    :isNumberRating="true"
                    :maxRating="field.max_rating_count"
                    :value="field.answer.rating_count"
                    @change="handleChangeRating"
                    :error="errors[0]"
                  />
                  <DatePicker
                    v-if="field.field_type_id.code === 4"
                    :name="`date_response_${index}`"
                    format="DD-MM-YYYY"
                    :value="field.answer.date_response"
                    @input="handleDatePickerChange"
                    :error="errors[0]"
                  />
                  <PhoneInputwithCountry
                    v-if="field.field_type_id.code === 9"
                    :name="`text_area_response_${index}`"
                    :value="field.answer.text_area_response"
                    :options="(options && options['dialingCode']) || []"
                    @change="handleInput"
                    :error="errors[0]"
                    ref="phone"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow class="mt-3">
          <CCol md="4" sm="12" class="mb-3">
            <CButton
              type="button"
              variant="outline"
              color="primary"
              class="w-100"
              @click="onCancel"
            >
              Cancel
            </CButton>
          </CCol>
          <!-- <CCol md="4" sm="12" class="mb-3">
            <CButton
              v-if="!register_now"
              type="submit"
              color="primary"
              class="w-100"
              @click="onSubmit(false)"
            >
              Save
            </CButton>
          </CCol> -->
          <CCol md="4" sm="12">
            <CButton
              type="submit"
              color="primary"
              class="w-100"
              @click="onSubmit(false)"
            >
              Submit
            </CButton>
          </CCol>
        </CRow>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import DateInput from "@/components/reusable/Fields/DateInput";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import Rating from "@/components/reusable/Fields/Rating";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import CampaignLogin from "@/pages/Campaign/CampaignLogin.vue";

import { extend } from "vee-validate";
import { email, regex, required } from "vee-validate/dist/rules";
import Vue from "vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

extend("email", { ...email, message: "Invalid email" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("required", { ...required, message: "This field is required" });
export default {
  name: "DynamicForm",
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    DateInput,
    RadioButton,
    CheckBox,
    TimePicker,
    PhoneInputwithCountry,
    Rating,
    CampaignLogin,
  },
  props: {
    dynamicFields: {
      type: Array,
      default: () => [],
    },
    candidateData: {
      type: Object,
      default: () => ({}),
    },
    isBasicForm: {
      type: Boolean,
      default: false,
    },
    register_now: {
      type: Boolean,
    },
  },
  data: () => ({
    fields: [],
  }),
  computed: {
    ...mapGetters(["dialingCodeWithCountryCode"]),
    options() {
      return {
        dialingCode: this.dialingCodeWithCountryCode || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        registerRecruitment: [
          { id: true, label: "Register Now" },
          { id: false, label: "Register Later" },
        ],
      };
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    handleChangeSelect(_name, value) {
      let val = value;
      if (!Array.isArray(value)) val = [value];
      const { name, index } = this.decodeName(_name);
      Vue.set(this.fields[index].answer, name, val);
    },
    handleRadio(_name, value) {
      const { name, index } = this.decodeName(_name);
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.fields[index].answer, name, val);
    },
    handleInput(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.fields[index].answer, name, value);
    },
    handleChangeRating(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.fields[index].answer, name, value);
    },
    handleDatePickerChange(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.fields[index].answer, name, value);
    },
    decodeName(data) {
      if (data) {
        const split_index = data.lastIndexOf("_");
        const name = data.substr(0, split_index);
        const index = data.substr(split_index + 1) || null;
        return { name, index };
      }
      return data;
    },
    async onSubmit(is_reg_completed) {
      const isValid = await this.$refs.dynamicForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      this.fields.is_reg_completed = is_reg_completed;
      this.$emit("callBack", this.fields);
    },
    onCancel(){
      this.$emit("cancelCallBack");
    },
    handleEmailInput(name, value) {
      Vue.set(this.candidate_email, name, value);
    },
    initForm() {
      this.fields =
        this.dynamicFields?.map((v) => ({
          field_type_id: {
            code: v?.question_type?.question_type_id,
            label: _.startCase(v?.question_type?.question_type_name),
          },
          field_name: v?.field_name,
          is_mandatory: v?.is_mandatory,
          options: v?.options,
          max_rating_count: v?.max_rating_count,
          field_id: v?.campaign_field_id,
          answer: {
            text_area_response:
              v?.campaign_field_id === 1
                ? this.candidateData.first_name
                : v?.campaign_field_id === 2
                ? this.candidateData.sur_name
                : v?.campaign_field_id === 3
                ? this.candidateData.email
                : v?.campaign_field_id === 4
                ? this.candidateData.phone
                : "",
          },
        })) || [];
      if (this.isBasicForm && this.fields.length) {
        this.fields = [
          ...this.fields,
          {
            field_type_id: {
              code: 1,
              label: "Yes Or No",
            },
            field_name:
              "Would you like to Register with the Careers & Recruitment Platform?",
            is_mandatory: true,
            options: [],
            max_rating_count: null,
            field_id: 5,
            answer: {
              yes_no_response: this.register_now
            },
          },
        ];
      }
      this.$nextTick(() => {
        this.$refs.dynamicForm.reset();
        this.$refs.dynamicForm.errors.clear;
      });
    },
  },
  mounted() {
    this.initForm();
  },
  watch: {
    dynamicFields() {
      this.initForm();
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-width{
  width: 120px;
}
</style>
