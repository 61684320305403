var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview"},[_c('ValidationObserver',{ref:"dynamicForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"name":"dynamicForm"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_vm._l((_vm.fields),function(field,index){return _c('CRow',{key:index,staticClass:"mb-2"},[(field.field_name)?_c('CCol',{attrs:{"md":"12"}},[_c('CRow',[_c('label',{staticClass:"col-lg-12 col-md-12",class:{ required: field.is_mandatory }},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(field.field_name))])]),(field.field_type_id)?_c('CCol',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":{
                  required: field.is_mandatory,
                  email: field.field_type_id.code === 8,
                  phone_rule:
                    field.field_type_id.code === 9 && /^\+\d{1,3}\^\d{1,15}$/,
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [([6, 7].includes(field.field_type_id.code))?_c('Select',{attrs:{"name":`options_response_${index}`,"value":field.answer.options_response,"options":field.options || [],"multiple":field.field_type_id.code === 7,"error":errors[0]},on:{"change":_vm.handleChangeSelect}}):_vm._e(),(
                    field.field_type_id.code === 1 && field.field_id === 5
                  )?_c('RadioButton',{attrs:{"name":`yes_no_response_${index}`,"value":field.answer.yes_no_response,"options":(_vm.options && _vm.options['registerRecruitment']) || [],"error":errors[0]},on:{"change":_vm.handleRadio}}):(field.field_type_id.code === 1)?_c('RadioButton',{attrs:{"name":`yes_no_response_${index}`,"value":field.answer.yes_no_response,"options":(_vm.options && _vm.options['boolean']) || [],"error":errors[0]},on:{"change":_vm.handleRadio}}):_vm._e(),([10].includes(field.field_type_id.code))?_c('TextareaInput',{attrs:{"name":`text_area_response_${index}`,"value":field.answer.text_area_response,"error":errors[0]},on:{"input":_vm.handleInput}}):_vm._e(),(
                    [2, 8].includes(field.field_type_id.code) &&
                    field.field_id !== 5
                  )?_c('TextInput',{attrs:{"name":`text_area_response_${index}`,"value":field.answer.text_area_response,"error":errors[0]},on:{"input":_vm.handleInput}}):_vm._e(),(field.field_type_id.code === 3)?_c('TextInput',{attrs:{"name":`numeric_response_${index}`,"type":"number","value":field.answer.numeric_response,"error":errors[0]},on:{"input":_vm.handleInput}}):_vm._e(),(field.field_type_id.code === 5)?_c('Rating',{attrs:{"name":`rating_count_${index}`,"isNumberRating":true,"maxRating":field.max_rating_count,"value":field.answer.rating_count,"error":errors[0]},on:{"change":_vm.handleChangeRating}}):_vm._e(),(field.field_type_id.code === 4)?_c('DatePicker',{attrs:{"name":`date_response_${index}`,"format":"DD-MM-YYYY","value":field.answer.date_response,"error":errors[0]},on:{"input":_vm.handleDatePickerChange}}):_vm._e(),(field.field_type_id.code === 9)?_c('PhoneInputwithCountry',{ref:"phone",refInFor:true,attrs:{"name":`text_area_response_${index}`,"value":field.answer.text_area_response,"options":(_vm.options && _vm.options['dialingCode']) || [],"error":errors[0]},on:{"change":_vm.handleInput}}):_vm._e()]}}],null,true)})],1):_vm._e()],1)],1):_vm._e()],1)}),_c('CRow',{staticClass:"mt-3"},[_c('CCol',{staticClass:"mb-3",attrs:{"md":"4","sm":"12"}},[_c('CButton',{staticClass:"w-100",attrs:{"type":"button","variant":"outline","color":"primary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")])],1),_c('CCol',{attrs:{"md":"4","sm":"12"}},[_c('CButton',{staticClass:"w-100",attrs:{"type":"submit","color":"primary"},on:{"click":function($event){return _vm.onSubmit(false)}}},[_vm._v(" Submit ")])],1)],1)],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }